import React from 'react';
import { Field, reduxForm } from 'redux-form';
// import validate from './validate';
import Button from 'react-bootstrap/Button';
import './wizard-style.css';

const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span>{error}</span> : false

const WizardFormFourthPage = props => {
  const { handleSubmit, previousPage, calcDelivery, tariffNames } = props;

  let calculatedDelivery = 0;
  return (
    <div className={'formInnerWrap'} style={{maxWidth: 500}}>
      <h2>Отметьте, как вы хотите получить заказ:</h2>
      <form onSubmit={handleSubmit}>
      <div>
        <div className={'checkboxes'}>
          {Object.keys(tariffNames).map(key => {
            calculatedDelivery = calcDelivery(props.data.delivery_tariff[key], props.data.quantity)

            return (<label className="radio-with-text-price" key={`delivery-type-${key}`}>
              <Field
                name="delivery_type"
                component="input"
                type="radio"
                value={key}
                style={{width: 'auto'}}
              />
              <span className="radio-title">
                {tariffNames[key]}
              </span>
              <span className="radio-price">
                {calculatedDelivery.first || calculatedDelivery.total} ₽
                {props.data.quantity > 1 && 
                  <small style={{display: 'block', marginBottom: '1rem'}}>
                    дополнительный весовой сбор: {calculatedDelivery.other_total} ₽
                  </small>
                }
              </span>
            </label>)
            
          })}
        </div>
      </div>
        <div className={'formActions'}>
          <Button type="button" variant="outline-secondary" className="previous" onClick={previousPage}>
            Назад
          </Button>
          <Button type="submit" className="next" variant="outline-primary">
            Далее
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate,
})(WizardFormFourthPage);