import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import countryList from './WizardFormThirdPage/countryList';
import './wizard-style.css';

class Confirm extends React.Component {
  render(){
    const {data, previousPage, filledPages, tariffNames} = this.props;
    const deliveryPrice = this.props.deliveryPriceInfo.total;
    const roboLink = `https://auth.robokassa.ru/Merchant/PaymentForm/FormMS.if?MerchantLogin=${data.MerchantLogin}&OutSum=${data.OutSum}&InvoiceID=${data.InvId}&Description=${data.InvDesc}&SignatureValue=${data.SignatureValue}&Shp_uuid=${data.Shp_uuid}&IsTest=${data.IsTest}&Culture=ru`;

    const handleInstalmentRequest = () => {
      const instalmentParams = {
        sum: parseFloat(data.OutSum),
        orderNumber: 'BOOKSCI' + data.id.toString(),
        customerEmail: data.user_email,
        customerPhone: data.user_phone,
        items: [
          {
            name: 'Книга с доставкой',
            quantity: 1,
            price: parseFloat(data.OutSum),
          }
        ],
        ...window.tinkoffCreditParams
      }
      console.log(JSON.stringify(instalmentParams))
      window.tinkoff.create(instalmentParams)
    }

    return(
      <div className={'confirmInnerWrap'}>

        <Card style={{marginBottom: '1rem'}}>
          <Card.Body>
            <Card.Title>Личные данные</Card.Title>
            <Container>
              {data.user_name &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text'>Имя:</div></Col>
                  <Col>{data.user_name}</Col>
                </Row>
              }
              {data.user_email &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text'>Почта:</div></Col>
                  <Col>{data.user_email}</Col>
                </Row>
              }
              {data.user_phone &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text'>Телефон:</div></Col>
                  <Col>{data.user_phone}</Col>
                </Row>
              }
            </Container>
          </Card.Body>
        </Card>

        <Card style={{marginBottom: '1rem'}}>
          <Card.Body>
            <Card.Title>Адрес доставки</Card.Title>
              <Container>
                {data.contact_full_name &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Получатель:</div></Col>
                    <Col>{data.contact_full_name}</Col>
                  </Row>
                }
                {data.contact_phone &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Телефон:</div></Col>
                    <Col>{data.contact_phone}</Col>
                  </Row>
                }
                {data.address_country &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Страна:</div></Col>
                    <Col>{countryList[data.address_country]}</Col>
                  </Row>
                }
                {data.address_reg &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Регион:</div></Col>
                    <Col>{data.address_reg}</Col>
                  </Row>
                }
                {data.address_obl &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Область:</div></Col>
                    <Col>{data.address_obl}</Col>
                  </Row>
                }
                {data.address_index &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Индекс:</div></Col>
                    <Col>{data.address_index}</Col>
                  </Row>
                }
                {data.address_city &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Город:</div></Col>
                    <Col>{data.address_city}</Col>
                  </Row>
                }
                {data.address_str &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Улица:</div></Col>
                    <Col>{data.address_str}</Col>
                  </Row>
                }
                {data.delivery_type &&
                  <Row>
                    <Col className='list-name-col'><div className='list-name-text'>Способ доставки:</div></Col>
                    <Col>
                      <small>
                        {tariffNames[data.delivery_type]}
                      </small>
                    </Col>
                  </Row>
                }
              </Container>
          </Card.Body>
        </Card>

        <Card style={{marginBottom: '1rem'}}>
          <Card.Body>
            <Card.Title>Книга</Card.Title>
            <Container>
              {data.book_title &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text'>Название:</div></Col>
                  <Col>{data.book_title}</Col>
                </Row>
              }
              {data.book_price &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text'>Цена:</div></Col>
                  <Col>{data.book_price} ₽</Col>
                </Row>
              }
              {data.quantity &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text'>Количество:</div></Col>
                  <Col>{data.quantity} шт.</Col>
                </Row>
              }
              {data.delivery_type &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text'>Стоимость доставки:</div></Col>
                  <Col>
                    <div>
                      {this.props.deliveryPriceInfo.first || this.props.deliveryPriceInfo.total} ₽
                    </div>
                    <small style={{display: 'block', marginBottom: '1rem'}}>
                      {this.props.data.quantity > 1 && 
                        <>
                          дополнительный весовой сбор: {this.props.deliveryPriceInfo.other_total} ₽
                        </>
                      }
                    </small>
                  </Col>
                </Row>
              }
              {data.delivery_type &&
                <Row>
                  <Col className='list-name-col'><div className='list-name-text' style={{fontSize:'150%'}}>К оплате:</div></Col>
                  <Col>
                    <div style={{fontSize:'150%'}}>
                      {deliveryPrice + (data.book_price * data.quantity) } ₽
                    </div>
                  </Col>
                </Row>
              }
            </Container>
          </Card.Body>
        </Card>

        <div className={'formActions'}>
          <Button type="button" variant="outline-secondary" className="previous" onClick={previousPage}>
            Назад
          </Button>
          <div>
            {data.MerchantLogin &&
              <iframe src={roboLink} width={300} height={70} style={{border: 0, width: 300, height:70, overflow: 'hidden', backgroundColor: 'transparent'}}/>
            }
            {window.tinkoffCreditParams && <div>
              <button className="btn btn-outline-success"
                onClick={handleInstalmentRequest}
              >{'В рассрочку за ' + Math.ceil(data.OutSum/6) + '₽ в месяц'}</button><br />
              <a href="https://www.book-sci.ru/instalments" target="_blank">Условия рассрочки</a>
            </div>}
          </div>

        </div>
      </div>
    )
  }
}

export default Confirm;