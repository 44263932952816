import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {renderField} from '../renderField';
import Button from 'react-bootstrap/Button';
import './style.css';


const validate = values => {
  const errors = {}
  if (!values.user_name) {
    errors.user_name = 'Имя не указано'
  } 
  if (!values.user_email) {
    errors.user_email = 'Почта не указана'
  } 
  if (!values.user_phone) {
    errors.user_phone = 'Телефон не указан'
  } 
  return errors
}

const WizardFormFirstPage = props => {
  const { handleSubmit } = props;
  
  return (
    <div className={'formInnerWrap'}>
    <h2>Укажите ваши личные данные</h2>
      <form onSubmit={handleSubmit}>
        <Field
          name="user_name"
          type="text"
          component={renderField}
          label="Фамилия, имя, отчество"
        />
        <Field
          name="user_email"
          type="email"
          component={renderField}
          label="Email"
          placeholder="example@mail.ru"
        />
        <Field
          name="user_phone"
          type="tel"
          component={renderField}
          label="Телефон"
          placeholder="7 123 456 78 90"
        />
        <div className={'formAction'}>
          <Button type="submit" className="next" variant="outline-primary">
            Далее
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(WizardFormFirstPage);