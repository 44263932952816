import React from 'react';
import { Field, reduxForm } from 'redux-form';
// import validate from './validate';
import Button from 'react-bootstrap/Button';
import '../wizard-style.css';
import {renderField, renderSelectFieldFromObject} from '../renderField';
import countryList from './countryList';

const validate = values => {
  const errors = {}
  if (!values.contact_full_name) {
    errors.contact_full_name = 'ФИО не указано'
  } 
  if (!values.contact_phone) {
    errors.contact_phone = 'Телефон не указан'
  } 
  if (!values.address_country) {
    errors.address_country = 'Страна не указана'
  } 
  if (!values.address_city) {
    errors.address_city = 'Город не указан'
  } 
  if (!values.address_str) {
    errors.address_str = 'Улица не указана'
  } 
  if (!values.address_index) {
    errors.address_index = 'Индекс не указан'
  } 
  return errors
}

const WizardFormFirstPage = props => {
  const { handleSubmit, previousPage } = props;
  return (
    
    <div className="container">
      <h2>Введите данные получателя:</h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
          <Field
            name="contact_full_name"
            type="text"
            component={renderField}
            label='Фамилия, имя, отчество'
            placeholder='Иванов Иван Иванович'
          />
          <Field
            name="contact_phone"
            type="tel"
            component={renderField}
            label='Телефон'
            placeholder="7 123 456 78 90"
          />
          </div>
          <div className="col-md-6">
          <Field
            name="address_country"
            type="select"
            component={renderSelectFieldFromObject}
            label='Страна'
            data={countryList}
          />
          <Field
            name="address_reg"
            type="text"
            component={renderField}
            label='Регион (необязательно)'
          />
          <Field
            name="address_obl"
            type="text"
            component={renderField}
            label='Область (необязательно)'
            placeholder="Ленинградская область"
          />
          <Field
            name="address_index"
            type="text"
            component={renderField}
            label='Индекс'
            placeholder="123456"
          />
          <Field
            name="address_city"
            type="text"
            component={renderField}
            label='Город'
            placeholder="Санкт-Петербург"
          />
          <Field
            name="address_str"
            type="text"
            component={renderField}
            label='Улица, дом, кв.'
            placeholder="Амбарная улица, дом 1, кв. 5"
          />
          </div>
        </div>
        <div className={'formActions'}>
          <Button type="button" variant="outline-secondary" className="previous" onClick={previousPage}>
            Назад
          </Button>
          <Button type="submit" variant="outline-primary" className="next">
            Далее
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(WizardFormFirstPage);