import React from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Button from 'react-bootstrap/Button';
import {renderField} from './renderField';
import './wizard-style.css';


const validate = values => {
  const errors = {}
  if (!values.quantity) {
    errors.quantity = 'Укажите количество'
  } 
  return errors
}


let WizardFormSecondPage = props => {
  const { handleSubmit, previousPage } = props;
  const {quantity} = props;
  const {book_price, book_title} = props.data;
  const under999 = value => {
    const v = parseInt(value)
    if (v === NaN)
      return ''
    if(v < 1) {
      return 1
    } else if(v > 999) {
      return 999
    } else {
      return value
    }
  }
  return (
    
    <div className={'formInnerWrap'}>
      <h2>Введите необходимое количество книг (шт.)</h2>
      <form onSubmit={handleSubmit}>
        <div className="quantity-block">
          <div className="quantity-row">
            <div>{book_title}</div>
            <div>{book_price} ₽</div>
          </div>
          <div className="quantity-row" style={{borderBottom: '1px solid #0000002b'}}>
            <div>Количество</div>
            <div style={{maxWidth: 120}}>
              <Field
                name="quantity"
                type="number"
                pattern="[0-9]*"
                defaultValue="1"
                component={renderField}
                normalize={under999}
              />
            </div>
          </div>
          <div className="quantity-row">
            <div></div>
            <div>{(quantity * book_price) || 0} ₽</div>
          </div>
        </div>
        <div className={'formActions'}>
          <Button type="button" variant="outline-secondary" className="previous" onClick={previousPage}>
            Назад
          </Button>
          <Button type="submit" variant="outline-primary" className="next">
            Далее
          </Button>
        </div>
      </form>
    </div>
  );
};

WizardFormSecondPage = reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(WizardFormSecondPage);

// Decorate with connect to read form values
const selector = formValueSelector('wizard') // <-- same as form name
WizardFormSecondPage = connect(state => {
  const quantity = selector(state, 'quantity')
  return {
    quantity
  }
})(WizardFormSecondPage)

export default WizardFormSecondPage