import React from 'react';
import Form from 'react-bootstrap/Form';
import './wizard-style.css';

const renderField = ({ input, placeholder, label, type, meta: { touched, error } }) => (
  <Form.Group controlId="formBasicEmail">
    <Form.Label>{label}</Form.Label>
    <Form.Control {...input} type={type} placeholder={placeholder}/>
    {touched && error &&
      <span className="errorMessage">{error}</span>
    }
  </Form.Group>
);


const renderSelectFieldFromObject = ({ input, placeholder, data, label, type, defaultValue, meta: { touched, error } }) => {
  return (
    <Form.Group controlId="wizard.ControlSelect1">
      <Form.Label>{label}</Form.Label>
      <Form.Control {...input} as="select" defaultValue={defaultValue} placeholder={placeholder}>
        <option></option>
        {Object.keys(data).map((key) => {
          return <option value={key} key={`${label}-option-${key}`}>{data[key]}</option>
        })}
      </Form.Control>
      {touched && error &&
        <span className="errorMessage">{error}</span>
      }
    </Form.Group>
  )
};

export {renderField, renderSelectFieldFromObject};