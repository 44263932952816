// Run this example by adding <%= javascript_pack_tag 'main' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.


// moved from hello_react.
// Must be rewrited
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { CookiesProvider } from 'react-cookie';

import store from './store';
import WizardForm from './components/WizardForm'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <CookiesProvider>
      <Provider store={store}>
        <WizardForm />
      </Provider>
    </CookiesProvider>,
    document.getElementById('app').appendChild(document.createElement('div'))
  )
})