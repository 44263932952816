import React, { Component } from 'react';
import axios from 'axios';
import { withCookies } from 'react-cookie';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { connect } from 'react-redux';

import WizardFormFirstPage from '../WizardFormFirstPage';
import WizardFormSecondPage from '../WizardFormSecondPage';
import WizardFormThirdPage from '../WizardFormThirdPage';
import WizardFormFourthPage from '../WizardFormFourthPage'

import './style.css';
import Confirm from '../confirmation';

class WizardForm extends Component {
  constructor(props) {
    super(props);

    const { cookies } = props;
    const params = new URLSearchParams(location.search);

    this.state = {
      uuid: params.get('uuid') || cookies.get('uuid'),
      page: 1,
    };
    this.gtagPageview = this.gtagPageview.bind(this);
  }

  onFirstPageSubmit = value => {
    const { cookies } = this.props;
    const { page } = this.state;

    this.setState({ page: page + 1 });

    axios.post('/api/order', value)
      .then(response => {
        let expires = new Date();
        expires.setMonth(expires.getMonth() + 3);

        cookies.set('uuid', response.data.uuid, { 
          path: '/', 
          expires: expires, 
        });
        this.setState({ uuid: response.data.uuid, data: response.data });
        this.gtagPageview();
      })
  }

  nextPage = (value) => {
    const { page } = this.state;

    this.setState({ page: page + 1 });
    if (this.filledPages(value).length === 4) value.status = 'finished'
    this.sendRequest(value);
  }

  previousPage = () => {
    const { page } = this.state;
    const { wizard } = this.props;

    this.setState({ page: page - 1 });
    this.sendRequest(wizard.values);
  }

  sendRequest = value => {
    const { uuid, page } = this.state;
    // this sets values of the third page name and phone 
    // to the same values of first page ones
    // 
    if (!value.contact_phone && value.user_phone) value.contact_phone = value.user_phone;
    if (!value.contact_full_name && value.user_name) value.contact_full_name = value.user_name;

    // set default country to russia
    // 
    if (!value.address_country) value.address_country = 'RU';

    // set default delivery_type to post_ru
    // 
    if (!value.delivery_type) value.delivery_type = 'post_ru';

    axios.post(`/api/order/${uuid}`, {
      ...value,
      last_page: page
    })
      .then(response => {
        this.setState({ data: response.data });
        this.gtagPageview();
      })
  }

  getData(){
    const { uuid } = this.state;
    if (uuid){
      axios.get(`/api/order/${uuid}`)
        .then(response => {
          const {status} = response.data;

          if (status === 'finished' || status === 'started') {
            this.setState({
              data: response.data,
              page: response.data.last_page
            });
            this.gtagPageview();
          } else {
            this.setState({
              uuid: null,
              data: null,
              page: 1,
            })
          }
        })
    }
  }

  gtagPageview(){
    const tid = localStorage.getItem('TRACKER_ID');
    const {uuid, page} = this.state;
    const {gtag} = window;
    const path = window.location.pathname;

    let params = {
      'page_title' : 'Shop',
      'page_path': `${path.slice(-1) === '/' ? path : path + '/'}p${page}`
    }
    if (uuid) params['user_id'] = uuid;

    //console.log(params);
    gtag('config', tid, params);
    if (page == 5) {
      fbq('track', 'InitiateCheckout')
    }
  }
  
  componentWillMount(){
    this.getData();
  }

  filledPages(data){
    let pages = [];
    if (!data) return pages;
    if (data.user_name && data.user_email && data.user_phone) pages.push(1);
    if (data.quantity) pages.push(2);
    if (data.contact_full_name && data.contact_phone && data.address_country && data.address_index && data.address_city && data.address_str) pages.push(3);
    if (data.delivery_type) pages.push(4);
    return pages;
  }

  calcDelivery(tariff, quantity){
    // catch tariff is undefined
    // 
    if (!tariff) return {total: '...'}
    if (quantity === 1) return {
      total: tariff.first,
    }
    let total = tariff.first;
    total += tariff.other * (quantity-1);
    return {
      total: total,
      method: `${tariff.first}₽ + ${tariff.other}₽ *${quantity-1}`,
      first: tariff.first,
      other_total: tariff.other * (quantity-1)
    }
  }

  render() {
    const { page } = this.state;
    const formData = this.state.data;
    const steps = [
      'Личные данные',
      'Количество книг',
      'Адрес доставки',
      'Способ доставки',
      'Подтверждение',
    ]
    const tariffNames = {
      post_ru: 'Обычным почтовым отправлением по России, 5-15 дней',
      ems_ru: 'Службой EMS (ускоренная по России), 1-4 дня',
      post_cis: 'Посылка в страны: Азербайджан, Армения, Беларусь, Грузия, Казахстан, Киргизия, Латвия, Литва, Молдова, Таджикистан, Украина, Эстония, 3-10 дней',
      ems_other: 'Службой EMS (ускоренная во все страны, кроме России), 2-5 дней',
      courier_msk: 'Курьерская доставка (только для Москвы), в течение суток',
      courier_new_msk: 'Курьерская доставка по Новой Москве, в течение суток',
      air: 'Воздушным сообщением во все страны, кроме России, 3-10 дней',
    }
    return (
      <>
        <Breadcrumb>
          {steps.map((step, i) => (
            <Breadcrumb.Item
              key={step}
              active={page === i+1}
              className={this.filledPages(formData).includes(i+1) ? 'filled' : 'not-filled'}
              onClick={() => {if (formData && this.filledPages(formData).includes(i+1)) this.setState({page: i+1})}}
            >
              {step}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className={'formMainWrap'}>
            {page === 1 && 
              <WizardFormFirstPage 
                onSubmit={this.state.uuid ? this.nextPage : this.onFirstPageSubmit}
                data={formData}
                initialValues={formData}
              />}
            {page === 2 && formData &&
              <WizardFormSecondPage
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                data={formData}
                initialValues={formData}
              />}
            {page === 3 &&
              <WizardFormThirdPage
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                data={formData}
                initialValues={formData}
                />}
            {page === 4 &&
              <WizardFormFourthPage
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                data={formData}
                initialValues={formData}
                calcDelivery={this.calcDelivery}
                tariffNames={tariffNames}
                />}
            {page === 5 &&
              <Confirm
                previousPage={this.previousPage}
                data={formData}
                filledPages={this.filledPages(formData)}
                deliveryPriceInfo={this.calcDelivery(formData.delivery_tariff[formData.delivery_type], formData.quantity)}
                tariffNames={tariffNames}
                />}
        </div>
      </>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    wizard: state.form.wizard,
  }
}

export default withCookies(connect(mapStateToProps)(WizardForm));